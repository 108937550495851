<template>
  <NForm
    ref="formRef"
    inline
    label-placement="left"
  >
    <NFormItem label="搜索标题：">
      <NInput
        maxlength="20"
        show-count
        clearable
        v-model:value="searchParams.title"
        @keyup.enter="handleSearch"
      />
    </NFormItem>
    <!-- <NFormItem label="资源类型：">
      <div style="width: 200px;">
        <NSelect :options="resourceTypeOptions" v-model:value="searchParams.type" />
      </div>
    </NFormItem> -->
    <NFormItem>
      <NSpace>
        <NButton type="primary" @click="handleSearch">搜索</NButton>
        <NButton @click="handleClear">清除</NButton>
      </NSpace>
    </NFormItem>
  </NForm>
</template>

<script setup>
  import { ref, reactive, computed, readonly, defineExpose, defineEmits } from 'vue';
  // import resourceTypesMap from '@/enumerators/resource-types-map.js';

  // const resourceTypeOptions = computed(() => Object.keys(resourceTypesMap).map(value => ({ label: resourceTypesMap[value], value })));
  const formRef = ref(null);
  const getDefaultSearchParams = () => ({
    title: '',
    type: null
  });
  const searchParams = reactive(getDefaultSearchParams());

  function getSearchParams() {
    const params = {};
    Object.keys(searchParams).forEach(key => {
      const value = searchParams[key];
      value && (params[key] = value);
    });
    return params;
  }

  const emit = defineEmits(['list-update']);
  function handleSearch() {
    emit('list-update');
  }
  function handleClear() {
    Object.assign(searchParams, getDefaultSearchParams());
    handleSearch();
  }

  defineExpose({
    getSearchParams
  });
</script>