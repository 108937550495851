<template>
  <NCard :bordered="false">
    <SearchForm ref="searchFormRef" @list-update="handleSearch(true)" />
    <div style="text-align: right;" v-if="ownUrlPermission('label/create')">
      <NButton type="primary" @click="toAddLabel()">新增标签</NButton>
    </div>
    <NTabs type="line" @before-leave="handleTabBeforeLeave" @update:value="handleTabChange">
      <NTabPane
        v-for="(resourceName, resourceType) in resourceTypes" :key="resourceType"
        :name="String(resourceType)"
        :tab="resourceName"
      >
        <div style="margin-bottom: 10px;">
          <NButton
            @click="toDelLabel('multiple')"
            :disabled="selectedIdsArr[resourceType].length === 0"
          >批量删除</NButton>
        </div>
        <NAlert
          v-show="selectedIdsArr[resourceType].length > 0"
          style="margin-bottom: 10px;"
          type="info"
        >已选择 <span class="theme-color">{{ selectedIdsArr[resourceType].length }}</span> 项数据</NAlert>
        <NDataTable
          :columns="columns"
          :row-key="row => row.id"
          :data="tableDataCache[resourceType]"
          :loading="loading"
          remote
          :pagination="paginations[resourceType]"
          @update:page="handlePageChange"
          @update:page-size="handlePageSizeChange"

          v-model:checked-row-keys="selectedIdsArr[resourceType]"
        />
      </NTabPane>
    </NTabs>
  </NCard>
  <AddLabel ref="addLabelRef" @label-added="handleSearch(false)" />
  <PageLoading :loading="requesting" />
</template>

<script setup>
  import { ref, reactive, computed, onMounted, h } from 'vue';
  import { NSpace, NButton, NTime } from 'naive-ui';

  import SearchForm from './components/SearchForm.vue';
  import AddLabel from './components/AddLabel.vue';
  import PageLoading from '@/components/PageLoading/index.vue';

  import resourceTypesMap from '@/enumerators/resource-types-map.js';
  import { getLabelList, delLabel } from '@/api/label.js';
  import { resStatusEnum } from '@/enumerators/http.js';
  import ownUrlPermission from '@/utils/own-url-permission.js';

  const { SUCCESS } = resStatusEnum;

  const requesting = ref(false);

  const searchFormRef = ref(null);
  function handleSearch(resetPage) {
    clearTableDataCache();
    clearAllTypeSelectedIds();
    resetPage ?
      handlePageChange(1) :
      updateTableData();
  }

  const resourceTypes = computed(() => ({
    0: '全部',
    ...resourceTypesMap
  }));
  const currentType = ref(0);
  function handleTabBeforeLeave() {
    return !loading.value;
  }
  function handleTabChange(name) {
    currentType.value = name;
    const resourceType = String(currentType.value);
    if (tableDataCache[resourceType].length === 0) {
      handlePageChange(1);
    }
  }

  const resourceTypesMapKeys = Object.keys(resourceTypes.value);
  const loading = ref(false);
  const allLabelColumns = [
    { type: 'selection' },
    { title: '标签', key: 'title' },
    {
      title: '类型',
      render: row => h(
        'span',
        null,
        { default: () => getResourceName(row.type) },
      )
    },
    {
      title: '创建者',
      key: 'admin_username'
    },
    {
      title: '更新日期',
      width: 220,
      render: row => h(
        NTime,
        {
          time: (Number(row.update_time) || 0) * 1000
        }
      )
    },
    {
      title: '操作',
      width: 160,
      render: row => h(
        NSpace,
        null,
        {
          default: () => {
            const tempArr = [];
            if (ownUrlPermission('label/update')) {
              tempArr.push(h(NButton, { text: true, type: 'info', onClick: () => toAddLabel(row) }, { default: () => '编辑' }));
            }
            if (ownUrlPermission('label/delete')) {
              tempArr.push(h(NButton, { text: true, type: 'error', onClick: () => toDelLabel('single', row) }, { default: () => '删除' }));
            }
            return tempArr;
          }
        }
      )
    }
  ];
  const columns = [...allLabelColumns];
  columns.splice(3, 0, {
    title: '内容数',
    key: 'file_num'
  });
  const tableDataCache = (() => {
    const cacheObj = {};
    resourceTypesMapKeys.forEach(key => {
      cacheObj[key] = [];
    });
    return reactive(cacheObj);
  })();
  function getResourceName(resourceType) {
    return resourceTypesMap[resourceType] || '';
  }
  function getLabelListReqData() {
    const type = String(currentType.value);
    const reqData = {
      ...searchFormRef.value.getSearchParams(),
      page: paginations[type].page,
      page_size: paginations[type].pageSize
    };
    type !== '0' && (reqData.type = type);
    return reqData;
  }
  function updateTableData() {
    if (loading.value) {
      return false;
    }
    const resourceType = String(currentType.value);
    loading.value = true;
    getLabelList(getLabelListReqData()).then(res => {
      loading.value = false;
      if (res.code === SUCCESS) {
        tableDataCache[resourceType] = res.data.list;
        updateItemCount(res.data.total);
      }
    }).catch(err => {
      loading.value = false;
    });
  }
  function clearTableDataCache() {
    resourceTypesMapKeys.forEach(key => {
      tableDataCache[key].splice(0);
      // paginations[key].page = 1;
    });
  }

  const pageSizes = [10, 20, 30];
  const paginations = (() => {
    const paginationObj = {};
    resourceTypesMapKeys.forEach(key => {
      paginationObj[key] = reactive({
        page: 1,
        itemCount: 0,
        pageSize: 10,
        pageSizes,
        showSizePicker: true
      });
    });
    return paginationObj;
  })();
  function updateItemCount(count) {
    const resourceType = String(currentType.value);
    paginations[resourceType].itemCount = Number(count) || 0;
  }
  function handlePageChange(page) {
    const resourceType = String(currentType.value);
    paginations[resourceType].page = page;
    updateTableData();
  }
  function handlePageSizeChange(pageSize) {
    const resourceType = String(currentType.value);
    paginations[resourceType].pageSize = pageSize;
    handlePageChange(1);
  }

  onMounted(() => {
    updateTableData();
  });

  const addLabelRef = ref(null);
  function toAddLabel(row) {
    addLabelRef.value.showModal(row);
  }
  const selectedIdsArr = (() => {
    const tempObj = {};
    resourceTypesMapKeys.forEach(key => {
      tempObj[key] = [];
    });
    return reactive(tempObj);
  })();
  function clearSelectedIds(type) {
    selectedIdsArr[type].splice(0);
  }
  function clearAllTypeSelectedIds() {
    resourceTypesMapKeys.forEach(key => {
      clearSelectedIds(key);
    });
  }
  function handleSelect(idsArr) {
    const resourceType = String(currentType.value);
    selectedIdsArr[resourceType] = idsArr;
  }
  function delLabelFn(idArr) {
    requesting.value = true;
    delLabel({
      'Label[ids]': idArr
    }).then(res => {
      requesting.value = false;
      if (res.code === SUCCESS) {
        window.$message.success(idArr.length > 1 ? '批量删除成功' : '删除成功');
        handleSearch(true);
        const resourceType = String(currentType.value);
        clearSelectedIds(resourceType);
      }
    }).catch(err => {
      requesting.value = false;
    });
  }
  function toDelLabel(type, singleRow) {
    const dialog = window.$dialog;
    switch (type) {
      case 'single':
        const { title, id } = singleRow;
        dialog.warning({
          title: '删除确认',
          content: `确定删除标签“${title}”？`,
          positiveText: '确定',
          negativeText: '取消',
          onPositiveClick: () => {
            delLabelFn([id]);
          },
          onNegativeClick: () => {}
        });
        break;
      case 'multiple':
        const resourceType = String(currentType.value);
        const ids = selectedIdsArr[resourceType];
        dialog.warning({
          title: '删除确认',
          content: `确定删除${ids.length}个标签？`,
          positiveText: '确定',
          negativeText: '取消',
          onPositiveClick: () => {
            delLabelFn(ids);
          },
          onNegativeClick: () => {}
        })
        break;
    }
  }
</script>

<style lang="less" scoped>
  @import "~@/styles/variables.less";
  .theme-color {
    color: @theme-color;
  }
</style>