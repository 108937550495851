<template>
  <NModal
    v-model:show="modalShow"
    preset="dialog"
    :title="modalTitle"
    style="width: 500px;"
    :show-icon="false"
    positive-text="确认"
    negative-text="取消"
    @positive-click="handleSubmit"
    @after-leave="clearModal"
    :mask-closable="false"
  >
    <NForm
      ref="formRef"
      label-placement="left"
      :label-width="120"
      style="padding: 25px 45px 0 0;"
      :model="formValue"
      :rules="formRules"
    >
      <NFormItem label="类型：" path="type">
        <NSelect
          :multiple="!isEdit"
          :options="typeOptions"
          v-model:value="formValue.type"
        />
      </NFormItem>
      <NFormItem label="标签名称：" path="title">
        <NInput
          maxlength="20"
          show-count
          clearable
          v-model:value="formValue.title"
        />
      </NFormItem>
    </NForm>
  </NModal>
  <PageLoading :loading="adding" />
</template>

<script setup>
  import { ref, reactive, computed } from 'vue';

  import PageLoading from '@/components/PageLoading/index.vue';

  import resourceTypesMap from '@/enumerators/resource-types-map.js';
  import { addLabel, editLabel } from '@/api/label.js';
  import { resStatusEnum } from '@/enumerators/http.js';
  const { SUCCESS } = resStatusEnum;

  const isEdit = ref(false);

  const adding = ref(false);
  const modalShow = ref(false);
  const modalTitle = ref('新增标签');
  const resReqParamsMap = {
    id: 'Label[id]',
    title: 'Label[title]',
    type: 'Label[type]'
  };
  const getDefaultFormValue = () => {
    const tempObj = {};
    Object.keys(resReqParamsMap).forEach(key => {
      switch (key) {
        case 'type':
          tempObj[key] = null;
          break;
        default:
          tempObj[key] = '';
      }
    });
    return tempObj;
  };
  const formRef = ref(null);
  const formValue = reactive(getDefaultFormValue());
  const formRules = computed(() => {
    return {
      title: {
        required: true,
        trigger: 'blur',
        message: '必填'
      },
      type: {
        type: isEdit.value ? 'string' : 'array',
        required: true,
        message: '必选',
        trigger: 'change'
      }
    };
  });
  const typeOptions = Object.keys(resourceTypesMap).map(value => ({
    label: resourceTypesMap[value],
    value
  }));
  function getReqData() {
    const reqData = {};
    Object.keys(resReqParamsMap).forEach(key => {
      switch(key) {
        case 'id':
          if (!!formValue[key]) {
            reqData[resReqParamsMap[key]] = formValue[key];
          }
          break;
        default:
          reqData[resReqParamsMap[key]] = formValue[key];
      }
    });
    return reqData;
  }
  function setFormValue(row) {
    Object.keys(resReqParamsMap).forEach(key => {
      formValue[key] = row[key];
    });
  }
  function clearModal() {
    Object.assign(formValue, getDefaultFormValue());
  }
  function showModal(row) {
    isEdit.value = Boolean(row);
    if (isEdit.value) { // 编辑
      modalTitle.value = '编辑标签';
      setFormValue(row);
    } else { // 新增
      modalTitle.value = '新增标签';
    }
    modalShow.value = true;
  }
  function closeModal() {
    modalShow.value = false;
  }
  const emit = defineEmits(['label-added']);
  function handleSubmit() {
    formRef.value.validate(errors => {
      if (!errors) {
        adding.value = true;
        let reqFn = null;
        if (!!formValue.id) { // 编辑
          reqFn = editLabel;
        } else { // 新增
          reqFn = addLabel;
        }
        reqFn(getReqData()).then(res => {
          adding.value = false;
          if (res.code === SUCCESS) {
            window.$message.success(`${modalTitle.value}成功`);
            closeModal();
            emit('label-added');
          }
        }).catch(err => {
          adding.value = false;
        });
      }
    });
    return false;
  }

  defineExpose({
    showModal
  });
</script>